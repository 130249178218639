import React, { FC, ReactElement, RefObject, useState, useEffect, useMemo } from "react"
import { AgGridReact } from "ag-grid-react"
import AgGrid from "components/AgGrid"
import { ColDef } from "ag-grid-community"
import {
  unitFields,
  assetFields,
  assetNonMappableFields,
  unitNonMappableFields
} from "constants/rentRollFields"
import { FormInstance } from "antd"
import {
  MappableUnitFields,
  ExportableUnitFields,
  MappableAssetFields,
  ExportableAssetFields
} from "@moodys/cre-cpm-client-apis.apis.services.auth"
import CheckboxCellRenderer from "components/AgGrid/CellRenderers/CheckBoxCellRenderer"
import FieldNameCellRenderer from "components/AgGrid/CellRenderers/FieldNameCellRenderer"
import { Envs } from "models/envs.model"
import {
  getRegionEnvironment,
  getDefaultFields,
  handleCheckboxChange,
  FieldProps
} from "./functions"

export interface RentRollFieldsTableProps {
  gridRef: RefObject<AgGridReact>
  mappableFields: MappableUnitFields[] | MappableAssetFields[]
  exportableFields: ExportableUnitFields[] | ExportableAssetFields[]
  type: string
  form: FormInstance
  isDefault: number
  userCompanyRegion: string
}

const RentRollFieldsTable: FC<RentRollFieldsTableProps> = ({
  gridRef,
  mappableFields,
  exportableFields,
  form,
  type,
  isDefault,
  userCompanyRegion
}): ReactElement => {
  const [mappable, setMappable] = useState<MappableUnitFields[] | MappableAssetFields[]>(
    mappableFields || []
  )
  const [exportable, setExportable] = useState<ExportableUnitFields[] | ExportableAssetFields[]>(
    exportableFields || []
  )

  if (type === "unit") {
    form.setFieldsValue({
      mappable_unit_fields: mappable,
      exportable_unit_fields: exportable
    })
  } else {
    form.setFieldsValue({
      mappable_asset_fields: mappable,
      exportable_asset_fields: exportable
    })
  }
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 100,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
      width: 200
    }
  }, [])

  const columns = [
    {
      headerName: "Field Name",
      field: "fieldName",
      filter: "agTextColumnFilter",
      cellRenderer: FieldNameCellRenderer,
      cellRendererParams: {
        type
      },
      filterParams: {
        defaultOption: "contains",
        maxNumConditions: 1,
        filterOptions: ["contains", "equals", "notEqual", "blank", "notBlank"]
      }
    },
    {
      headerName: "Available for Mapping",
      field: "availableForMapping",
      filter: "agSetColumnFilter",
      cellRenderer: CheckboxCellRenderer,
      cellRendererParams: {
        handleCheckboxChange: (data: FieldProps["data"]) => {
          handleCheckboxChange(data, "availableForMapping", mappable, setMappable)
        },
        type,
        mode: "mapping"
      }
    },
    {
      headerName: "Available for Export",
      field: "availableForExport",
      filter: "agSetColumnFilter",
      cellRenderer: CheckboxCellRenderer,
      cellRendererParams: {
        handleCheckboxChange: (data: FieldProps["data"]) => {
          handleCheckboxChange(data, "availableForExport", exportable, setExportable)
        },
        type,
        mode: "export"
      }
    }
  ]

  let rowData = []

  if (type === "unit") {
    rowData = unitFields.map((fieldName) => ({
      fieldName,
      availableForMapping: !(mappable as MappableUnitFields[]).includes(
        fieldName as MappableUnitFields
      ),
      availableForExport: !(exportable as ExportableUnitFields[]).includes(
        fieldName as ExportableUnitFields
      ),
      disabled: unitNonMappableFields.includes(fieldName)
    }))
  } else {
    rowData = assetFields.map((fieldName) => ({
      fieldName,
      availableForMapping: !(mappable as MappableAssetFields[]).includes(
        fieldName as MappableAssetFields
      ),
      availableForExport: !(exportable as ExportableAssetFields[]).includes(
        fieldName as ExportableAssetFields
      ),
      disabled: assetNonMappableFields.includes(fieldName)
    }))
  }

  useEffect(() => {
    if (isDefault > 0) {
      const env = process.env.REACT_APP_ENV as Envs
      const regionEnvironment = getRegionEnvironment(env, userCompanyRegion)
      setMappable(
        getDefaultFields(type, regionEnvironment, "mapping") as
          | MappableAssetFields[]
          | MappableUnitFields[]
      )
      setExportable(
        getDefaultFields(type, regionEnvironment, "export") as
          | ExportableAssetFields[]
          | ExportableUnitFields[]
      )
    }
  }, [isDefault])

  return (
    <div className="ag-theme-alpine" style={{ width: "100%", height: "40vh" }}>
      <AgGrid ref={gridRef} rowData={rowData} columnDefs={columns} defaultColDef={defaultColDef} />
    </div>
  )
}

export default RentRollFieldsTable
