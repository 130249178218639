import React, { FC } from "react"
import { Row, Space, Form, Tooltip } from "antd"
import { FieldProps } from "pages/Admin/Companies/Edit/RentRollTab/RentRollFieldsTable/functions"
import { useTranslation } from "react-i18next"
import FieldCheckbox from "components/Admin/FeatureAccessList/FeatureAccessCheckbox"

export interface FieldPropsCellRenderer extends FieldProps {
  data: {
    fieldName: string
    availableForMapping: boolean
    availableForExport: boolean
    disabled: boolean
  }
  handleCheckboxChange: (data: FieldProps["data"]) => void
  type: string
  mode: "mapping" | "exporting"
}

const CheckboxCellRenderer: FC<FieldPropsCellRenderer> = (props) => {
  const { data, handleCheckboxChange, type, mode } = props
  const { t } = useTranslation("auth")

  const isMappingMode = mode === "mapping"
  const checkboxName = isMappingMode ? "mapping" : "export"
  const formItemName = isMappingMode ? `mappable_${type}_fields` : `exportable_${type}_fields`
  const checkboxDisabled = isMappingMode ? data.disabled : false
  let checkboxChecked

  if (checkboxDisabled) {
    checkboxChecked = false
  } else if (isMappingMode) {
    checkboxChecked = data.availableForMapping
  } else {
    checkboxChecked = data.availableForExport
  }

  return (
    <Row className="ant-row-center">
      <Space direction="horizontal">
        <Form.Item name={formItemName}>
          {checkboxDisabled ? (
            <Tooltip
              title={t("companies.rent_roll.checkbox_tooltip")}
              overlayStyle={{ maxWidth: "100px" }}
            >
              <span>
                <FieldCheckbox
                  checked={checkboxChecked}
                  onChange={() => handleCheckboxChange(data)}
                  disabled={checkboxDisabled}
                  name={checkboxName}
                  id={checkboxName}
                />
              </span>
            </Tooltip>
          ) : (
            <FieldCheckbox
              checked={checkboxChecked}
              onChange={() => handleCheckboxChange(data)}
              disabled={checkboxDisabled}
              name={checkboxName}
              id={checkboxName}
            />
          )}
        </Form.Item>
      </Space>
    </Row>
  )
}

export default CheckboxCellRenderer
