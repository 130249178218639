import React, { useContext, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { removeAllCookies, saveAuthCookie } from "@moodys/cre-cpm.functions.cookies"
import { MapSSO } from "@moodys-ma-platform/auth-sdk"
import { redirectTo } from "@moodys/cre-cpm.functions.window-location"
import { AuthContext } from "contexts/AuthContext"
import SsoLoader from "./components/SsoLoader"

interface SsoEntrypointProps {}

const SsoEntrypoint: React.FC<SsoEntrypointProps> = () => {
  const { sso } = useContext(AuthContext)
  const history = useHistory()

  const syncAuthTokens = async (ssoInstance: MapSSO) => {
    const auth = await ssoInstance.handleRedirect()
    const callback = new URL(auth.state).searchParams.get("callback")

    if (!(await ssoInstance.isAuthenticated()) || !auth.tokens.accessToken?.token) {
      await ssoInstance.signOut()
      history.push("/sso/login")
    } else {
      removeAllCookies()
      saveAuthCookie(true, auth.tokens.accessToken.token, 1)
      if (callback) {
        redirectTo(callback)
      } else {
        history.push("admin/users")
      }
    }
  }

  useEffect(() => {
    if (sso) {
      syncAuthTokens(sso)
    } else {
      throw new Error("SSO SDK is not available")
    }
  }, [])

  return <SsoLoader />
}

export default SsoEntrypoint
